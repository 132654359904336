<template>
  <master-layout bgColor="transparent">
  </master-layout>
</template>

<script>
import axios from 'axios'
import { BarcodeScanner } from '@capacitor-community/barcode-scanner'
import { mapGetters, mapActions } from 'vuex'
// import {
//   loadingController
// } from '@ionic/vue'
import store from '../store/index'

export default {
  name: 'CheckinHybrid',
  computed: {
    ...mapGetters('wineries', {
      wineryInMemory: 'getWineryInMemory'
    })
  },
  beforeRouteEnter(to, from, next) {
    console.log(to + from + next)
    let wineryInMemory = store.getters['wineries/getWineryInMemory']
    // if(wineryInMemory != ''){
    if(wineryInMemory){
      // return next({ path: '/app/confirm' + wineryInMemory  })
      return next({ path: '/app/confirm' })
    } else {
      return next()
    }
  },
  ionViewWillEnter () {
    this.checkPermission()
  },
  ionViewWillLeave () {
    this.stopScan()
  },
  methods: {
    ...mapActions('wineries', {
      setWineryInMemory: 'setWineryInMemory'
    }),

    async startScan () {
      BarcodeScanner.hideBackground() // make background of WebView transparent
      const result = await BarcodeScanner.startScan() // start scanning and wait for a result
      // if the result has content
      if (result.hasContent) {
        let urlFromScan = result.content
        if(urlFromScan != urlFromScan.replace('https://www.orszagosbortura.hu/boraszatok/', '')){
          // this.presentLoading()
          let wineryId = urlFromScan.replace('https://www.orszagosbortura.hu/boraszatok/', '').replace('/', '')
          this.getWinery(wineryId)
        } else {
          alert('Ez nem Országos Bortúra QR-kód. Próbálj ki egy másik kódot!')
          this.startScan()
        }
      } else {
        alert('Ez nem Országos Bortúra QR-kód. Próbálj ki egy másik kódot!')
        this.startScan()
      }
    },

    async getWinery (wineryId) {
      try {
        var thisWinery = await axios.get(process.env.VUE_APP_STRAPI_URI + 'wineries/' + wineryId)
        this.setWineryInMemory(thisWinery.data)
        // await this.loading.dismiss()
        this.$router.push('/app/confirm')
      } catch (error) {
        // await this.loading.dismiss()
        alert("Hiba történt a QR kód beolvasással.")
        this.startScan()
        // console.log('An error occurred:', error.response.data.error)
      }
    },

    stopScan () {
      BarcodeScanner.showBackground()
      BarcodeScanner.stopScan()
    },

    async checkPermission () {
      const status = await BarcodeScanner.checkPermission({ force: false })
      if (status.granted) {
        // the user granted permission
        this.startScan()
        return true
      }
      if (status.denied) {
        // the user denied permission for good
        // redirect user to app settings if they want to grant it anyway
        const c = confirm(
          'Engedélyezd a kamera használatát az applikáció beállításainál.'
        )
        if (c) {
          BarcodeScanner.openAppSettings()
        }
      }

      if (status.asked) {
        // system requested the user for permission during this call
        // only possible when force set to true
      }

      if (status.neverAsked) {
        // user has not been requested this permission before
        // it is advised to show the user some sort of prompt
        // this way you will not waste your only chance to ask for the permission
        const c = confirm(
          'Az Országos Bortúra szeretne hozzáférni a kamerádhoz, hogy beolvashasd a QR-kódokat.'
        )
        if (!c) {
          return false
        }
      }

      if (status.restricted || status.unknown) {
        // ios only
        // probably means the permission has been denied
        return false
      }

      // user has not denied permission
      // but the user also has not yet granted the permission
      // so request it
      const statusRequest = await BarcodeScanner.checkPermission({ force: true })

      if (statusRequest.asked) {
        // system requested the user for permission during this call
        // only possible when force set to true
      }

      if (statusRequest.granted) {
        // the user did grant the permission now
        // launch scanning  here
        this.startScan()
        return true
      }

      // user did not grant the permission, so he must have declined the request
      return false
    },

    deactivated () {
      this.stopScan()
    },

    beforeDestroy () {
      this.stopScan()
    },

    // async presentLoading() {
    //   this.loading = await loadingController
    //     .create({
    //       spinner: 'lines'
    //     })
    //   await this.loading.present()
    // }
  }
}
</script>
